import { graphql } from 'gatsby'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import Image from "gatsby-image"
import styled from "styled-components"
import React from 'react'
import Layout from '../components/layout/layout'
import * as styles from '../components/blog.module.css';
import Commentsimg from '../images/comments.svg';
import FeaturedPosts from './featuredPosts';
import ProfileImg from '../images/profile.svg';
import Facebook from '../images/facebookBlue.png';
import Twitter from '../images/twitterBlue.png';
import Linkedin from '../images/linkedinBlue.png';
import MailBlue from '../images/mailBlue.png';

export const query = graphql
    `
    query PostsByID($slug: String!) {
        mdx(
            fields: { slug: { eq: $slug } }
        ){
            body
            frontmatter {
                title
                date(formatString: "MMMM DD, YYYY ")
                postimage {
                    childImageSharp {
                        fluid(maxWidth: 800) {
                          ...GatsbyImageSharpFluid_noBase64
                        }
                    }  
                }
            }
        }
    }
`

const PostImage = styled(Image)`
  width: 100%;
  height:100%;
`

export default function ({ data}) {
    const { frontmatter, body } = data.mdx
    return (
        <Layout>
        {/* <div>
            <a href="/blog">Back to Posts</a>
        </div>
        <div>
            {frontmatter.postimage && (
                <PostImage
                fluid={frontmatter.postimage.childImageSharp.fluid}
                alt="Banner Image"
            />
            )}
            <h1>{frontmatter.title}</h1>
            <p>{frontmatter.date}</p>
            <MDXRenderer>{body}</MDXRenderer>
        </div> */}
        <div id="fb-root"></div>
          <div className={styles.landingSection}>
            <div className={styles.landingSectionSubDiv}>
              <text className={styles.blogHeader}>{frontmatter.title}</text>
              <div className={styles.subtitle}>
                <div className={styles.profileCol2}>
                    <img className={styles.ProfileImg} src={ProfileImg} alt="landingimg" />
                  <text className={styles.profileTest}>By David Ealy</text>
                  <p>{frontmatter.date}</p>
                </div>
                <div className={styles.profileCol2} id={styles.profilecolId}>
                  <img className={styles.ProfileImg} src={Commentsimg} alt="landingimg" />
                  <text className={styles.profileTest}>0 Comments</text>
                </div>
                </div>
                {/* <div className="blogImg">
                    {frontmatter.postimage && (
                        <PostImage
                        fluid={frontmatter.postimage.childImageSharp.fluid}
                        alt="Banner Image"
                    />
                    )}
                </div> */}
                <text className={styles.blogDesc}>
                <MDXRenderer>{body}</MDXRenderer>
                </text>
                </div>
                <div className={styles.BlogsocialLinksDiv}>
                <img className={styles.social} src={Facebook} alt="landingimg" />
                <img className={styles.social} src={Twitter} alt="landingimg" />
                <img className={styles.social} src={Linkedin} alt="landingimg" />
                <img className={styles.social} src={MailBlue} alt="landingimg" />
              </div>
              <iframe name="f297624ad60196" width="500px" height="100px" data-testid="fb:comments Facebook Social Plugin" title="fb:comments Facebook Social Plugin" frameborder="0" allowtransparency="true" allowfullscreen="true" scrolling="no" allow="encrypted-media" src="https://www.facebook.com/v2.0/plugins/comments.php?app_id=691895914788460&amp;channel=https%3A%2F%2Fstaticxx.facebook.com%2Fx%2Fconnect%2Fxd_arbiter%2F%3Fversion%3D46%23cb%3Df247eedd96682d4%26domain%3Dfriendly-curran-11b640.netlify.app%26is_canvas%3Dfalse%26origin%3Dhttps%253A%252F%252Ffriendly-curran-11b640.netlify.app%252Ffe72a2fa7cef6c%26relation%3Dparent.parent&amp;color_scheme=light&amp;container_width=714&amp;height=100&amp;href=https%3A%2F%2Fwww.facebook.com%2Fbla%2F&amp;locale=en_US&amp;numposts=6&amp;sdk=joey&amp;version=v2.0&amp;width=500" style={{border: 'none', visibility: 'visible', width: '500px', height: '458px'}} class=""></iframe>
            </div>
            <div className={styles.featuredNewsDiv}>
                <text>Featured News</text>
              </div>
            <div>
          <FeaturedPosts/>
        </div>
        </Layout>
    )
}